
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

export default {
  name: 'PagesAffiliateAdminSubmissionsIndex',
  layout: 'admin',
  apollo: {
    partnershipWithAffiliateEnabledIds: {
      query: GET_PARTNERSHIPS_QUERY,
      variables: {
        filters: {
          affiliateEnabled: true,
        },
      },
      update: (data) => {
        const partnerships = data.getPartnerships ?? [];
        return [
          {
            text: '-',
            value: null,
          },
          ...partnerships.map((partnership) => ({
            text: partnership.name,
            value: partnership.id,
          })),
        ];
      },
      fetchPolicy: 'network-only',
    },
  },
  data() {
    return {
      columns: ['ID', 'Name', 'Email', 'Status', 'Updated'],
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Not Started',
          value: 'NOT_STARTED',
        },
        {
          label: 'In Progress',
          value: 'IN_PROGRESS',
        },
        {
          label: 'Awaiting Review',
          value: 'AWAITING_APPROVAL',
        },
        {
          label: 'Flagged',
          value: 'FLAGGED',
        },
        {
          label: 'Approved',
          value: 'APPROVED',
        },
        {
          label: 'Archived',
          value: 'ARCHIVED',
        },
      ],
      productAddOnFilters: [
        {
          text: 'All',
          value: '',
        },
        {
          text: 'Without Legal Advice Booking',
          value: 'WITHOUT_LEGAL_ADVICE_BOOKING',
        },
        {
          text: 'With Legal Advice Booking',
          value: 'WITH_LEGAL_ADVICE_BOOKING',
        },
        {
          text: 'With Legal Advice',
          value: 'WITH_LEGAL_ADVICE',
        },
        {
          text: 'Will',
          value: 'WILL',
        },
        {
          text: 'Will Tier Two',
          value: 'WILL_TIER_TWO',
        },
      ],
      partnershipWithAffiliateEnabledIds: [],
    };
  },
  computed: {
    ...mapGetters('admin/submissions', [
      'wills',
      'pageSize',
      'submissionsAffiliateId',
      'submissionsStatusFilter',
      'submissionsProductAddOnFilter',
      'submissionsPageIndex',
      'submissionsSearch',
      'submissionsSort',
      'submissionsSortingDirection',
      'submissionsSortableColumns',
      'submissionsSortableFields',
    ]),
    searchQuery: {
      get() {
        return this.submissionsSearch;
      },
      set(value) {
        this.setSubmissionsSearch(value);
      },
    },
    productAddOnFilter: {
      get() {
        return this.submissionsProductAddOnFilter;
      },
      set(value) {
        this.setSubmissionsProductAddOnFilter(value);
      },
    },
    searchByAffiliateId: {
      get() {
        return this.submissionsAffiliateId;
      },
      set(value) {
        this.setSubmissionsAffiliateId(value);
      },
    },
  },
  async mounted() {
    await this.refetchWills();
  },
  methods: {
    ...mapActions('admin/submissions', [
      'refetchWills',
      'nextSubmissionsPage',
      'prevSubmissionsPage',
      'setSubmissionsStatusFilter',
      'setSubmissionsProductAddOnFilter',
      'setSubmissionsSearch',
      'setSubmissionsSort',
      'setSubmissionsAffiliateId',
    ]),
    async nextPage() {
      if (!this.$apollo.loading) {
        await this.nextSubmissionsPage();
      }
    },
    async prevPage() {
      if (!this.$apollo.loading) {
        await this.prevSubmissionsPage();
      }
    },
    search: debounce(300, async function (value) {
      await this.setSubmissionsSearch(value.trim());
    }),
  },
};
